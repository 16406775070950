import React from 'react';

const Question = (props) => {
 
   return (
     <div>
        <h1>{props.currentQuestion}</h1>
     </div>
   )
}
 
export default Question;